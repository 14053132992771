/* eslint-disable object-curly-newline */
import * as CustomComponents from "./CustomComponents";
import * as Resources from "./Resources";
import * as Exporters from "./exporters";
import * as Choices from "./SelectChoices";

const BlueprintConfig = {
  company: {
    title: "Companies",
    record_representation: "name",
    backend_resource: "companies",
    resource_id_key: "company_id",
    mapped_to: [
      "noyo",
      "justworks",
      "benefits_registry",
      "justworks_payroll",
      "optum",
      "justworks_cdms",
      "clockwork_pkid",
    ],
    filters: ["name", "ein", "phone_number", "waiting_period"],
    representation_fields: ["name"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        custom_component: CustomComponents.ClickToCopyCompanyId(),
      },
      {
        source: "name",
        label: "Name",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "notes",
        label: "Notes",
        type: "truncated",
        multiline: true,
        style: { width: "400px" },
      },
      {
        source: "ein",
        label: "EIN",
        helperText: "EIN must be 9 digits.",
        validations: {
          maxLength: 9,
          minLength: 9,
          validationMessage: "EIN must be 9 digits.",
        },
      },
      {
        source: "entity_type",
        label: "Entity Type",
        choices: Choices.ENTITY_TYPES,
        type: "drop-down",
        exclude: ["List"],
      },
      {
        source: "phone_number",
        label: "Phone Number",
        helperText: "Phone Number must be 10 digits.",
        type: "phoneNumber",
        validations: {
          maxLength: 10,
          minLength: 10,
        },
      },
      {
        source: "payroll_provider",
        label: "Payroll Provider",
        choices: Choices.PAYROLL_PROVIDERS,
        type: "drop-down",
        exclude: ["List"],
      },
      {
        source: "founding_date",
        label: "Founding Date",
        type: "date",
        exclude: ["List"],
      },
      {
        source: "billing_info.exclude_from_billing",
        label: "Exclude from Billing",
        type: "boolean",
      },
      {
        source: "external_ids.justworks",
        label: "Justworks PEO ID",
      },
      {
        source: "billing_info.broker_of_record_transfer_dates.medical",
        label: "Medical Broker of Record Transfer Date",
        type: "date",
      },
      {
        custom_component: CustomComponents.CompanyDashboardButton(),
        exclude: ["Create", "Edit"],
      },
      {
        source: "noyo_member_snapshot_enabled",
        label: "Noyo Member Snapshot Enabled",
        type: "boolean",
        exclude: ["List"],
      },
    ],
    components: ["Show", "Create", "Edit", "List"],
    sub_resources: [
      {
        tab_name: "Connections",
        backend_resource: "company_provider_connections",
        linking_field: "company_id",
        attributes: [
          {
            source: "name",
            label: "Provider",
            reference: [["providers", "provider_id"]],
            sortable: true,
          },
          { source: "provider_company_id", label: "Provider Company ID" },
          {
            source: "year",
            label: "Last Enrolled Plan Year",
            reference: [["plan_years", "last_enrolled_plan_year_id"]],
          },
          {
            custom_component:
              CustomComponents.CompanyMedicalInsuranceSetupManager(),
            exclude: ["Create", "Edit"],
          },
        ],
        components: ["Show", "Create", "List"],
      },
      {
        tab_name: "Benefit Offerings",
        backend_resource: "company_benefit_offerings",
        linking_field: "company_id",
        attributes: [
          {
            source: "year",
            label: "Plan Year",
            reference: [["plan_years", "plan_year_id"]],
            sort_params: ["start_date", "DESC"],
          },
          {
            source: "name",
            label: "Company Benefit Cycle",
            reference: [["company_benefit_cycles", "company_benefit_cycle_id"]],
            filter_params: [["company_id", "company_id"]],
          },
          {
            source: "offering_start_date",
            label: "Company Benefit Selection Window",
            reference: [
              [
                "company_benefit_selection_windows",
                "company_benefit_selection_window_id",
              ],
            ],
            filter_params: [["company_id", "company_id"]],
          },
          {
            source: "benefit_type",
            label: "Benefit Type",
            choices: Choices.BENEFIT_TYPE,
            type: "drop-down",
          },
          {
            source: "name",
            label: "Providers",
            reference: [["providers", "provider_ids"]],
            type: "multi-reference",
            sort_params: ["name", "ASC"],
          },
          {
            source: "offering_start_date",
            label: "Offering Start Date",
            type: "date",
            validations: {
              required: true,
            },
          },
          {
            source: "offering_end_date",
            label: "Offering End Date",
            type: "date",
            validations: {
              required: true,
            },
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.BENEFIT_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            defaultValue: "new",
            validations: {
              required: true,
            },
          },
          {
            custom_component: CustomComponents.RolloverButton(),
            exclude: ["Create", "Edit", "List"],
          },
          {
            custom_component: CustomComponents.DownloadPriceGridsButton(),
            exclude: ["Create", "Edit", "List"],
          },
        ],
        components: ["Show", "List", "Create"],
      },
      {
        tab_name: "Plan Offerings",
        backend_resource: "company_plan_offerings",
        linking_field: "company_id",
        sort_order: "DESC",
        sort_field: "selection_status",
        filters: ["Provider Offering", "selection_status"],
        attributes: [
          {
            source: "display_name",
            label: "Provider Offering",
            reference: [
              [
                "company_benefit_offering_providers",
                "company_benefit_offering_provider_id",
              ],
            ],
            validations: { required: true },
            filter_params: [["company_id", "company_id"]],
            sortable: true,
          },
          {
            source: "display_name",
            label: "Plan Name",
            reference: [["plans", "plan_id"]],
            sortable: true,
            filter_params: [
              ["company_benefit_offering_id", "company_benefit_offering_id"],
            ],
            exclude: ["Create", "Edit"],
          },
          {
            source: "year",
            label: "Plan Year",
            reference: [
              [
                "company_benefit_offering_providers",
                "company_benefit_offering_provider_id",
              ],
              ["plan_years", "plan_year_id"],
            ],
            sortable: true,
            exclude: ["Create", "Edit"],
          },
          {
            source: "name",
            label: "Plan Code",
            reference: [["plans", "plan_id"]],
            sortable: true,
            exclude: ["Create", "Edit"],
          },
          {
            source: "plan_id",
            label: "Plan",
            reference: [["plans", "plan_id"]],
            type: "autocomplete",
            input_text_attributes: [
              "provider_name",
              "year",
              "name",
              "display_name",
            ],
            exclude: ["List", "Show"],
          },
          {
            source: "selection_status",
            label: "Status",
            choices: Choices.COMPANY_PLAN_OFFERING_SELECTION_STATUS,
            type: "drop-down",
            defaultValue: "confirmed",
          },
        ],
        components: ["Show", "List", "Create"],
      },
      {
        tab_name: "Insurance Classes",
        backend_resource: "insurance_classes",
        linking_field: "company_id",
        attributes: [
          { source: "name", label: "Name", sortable: true },
          {
            source: "waiting_period",
            label: "Waiting Period",
            type: "number",
          },
          {
            source: "disabled_on",
            label: "Disabled on",
            type: "date",
            sortable: true,
          },
        ],
        components: ["Show", "List", "Create"],
      },
      {
        tab_name: "Members",
        backend_resource: "members",
        linking_field: "company_id",
        attributes: [
          {
            source: "first_name",
            validations: { required: true },
            label: "First Name",
            sortable: true,
          },
          {
            source: "middle_name",
            label: "Middle Name",
            exclude: ["List"],
            sortable: true,
          },
          {
            source: "last_name",
            validations: { required: true },
            label: "Last Name",
            sortable: true,
          },
          {
            source: "preferred_name",
            label: "Preferred Name",
            sortable: true,
          },
          {
            source: "sex",
            validations: { required: true },
            label: "Sex",
            exclude: ["List"],
            choices: Choices.SEX,
            type: "radio",
          },
          {
            source: "date_of_birth",
            validations: { required: true },
            label: "DOB",
            type: "date",
            exclude: ["List"],
          },
          { source: "ssn", label: "SSN", type: "ssn", exclude: ["List"] },
          { source: "ssn", label: "SSN", exclude: ["Show", "Create", "Edit"] },
          {
            source: "name",
            label: "Insurance Class",
            reference: [
              ["member_insurance_classes", "member_id", "inverted"],
              ["insurance_classes", "insurance_class_id"],
            ],
            readonly: true,
          },
          {
            source: "ability_groups",
            label: "Ability Groups",
            options_reference: ["ability_groups", "name"],
            type: "multi-checkbox",
          },
          {
            source: "cdms_canonical_id",
            label: "Canonical ID",
          },
        ],
        components: ["Show", "List", "Create"],
        exporter: Exporters.MemberExporter,
      },
      {
        tab_name: "Contributions",
        backend_resource: "company_plan_offering_contributions",
        linking_field: "company_id",
        attributes: [
          {
            source: "display_name",
            label: "Plan Offering",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
          },
          {
            source: "year",
            label: "Plan Year",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
              ["plan_years", "plan_year_id"],
            ],
            exclude: ["Create", "Edit"],
          },
          {
            source: "name",
            label: "Insurance Class",
            reference: [["insurance_classes", "insurance_class_id"]],
            filter_params: [["company_id", "company_id"]],
            sortable: true,
          },
          {
            source: "mode",
            label: "Mode",
            choices: Choices.CONTRIBUTION_MODE,
            type: "drop-down",
          },
          {
            source: "display_name",
            label: "Reference Plan Offering",
            reference: [
              ["company_plan_offerings", "reference_company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
          },
          {
            source: "employee_contribution",
            label: "Employee Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_spouse_contribution",
            label: "Spouse Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_children_contribution",
            label: "Children Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_family_contribution",
            label: "Family Tier",
            type: "contribution",
          },
        ],
        components: ["Show", "List", "Create"],
      },
      {
        tab_name: "Address",
        backend_resource: "company_addresses",
        linking_field: "company_id",
        timeline_identifiers: [
          {
            label: "Address Type",
            name: "address_type",
            choices: Choices.COMPANY_ADDRESS_TYPE_CHOICES,
          },
        ],
        attributes: [
          {
            source: "street_one",
            label: "Street Line 1",
            validations: { required: true },
          },
          {
            source: "street_two",
            label: "Street Line 2",
          },
          { source: "city", label: "City", validations: { required: true } },
          {
            source: "state",
            label: "State",
            type: "drop-down",
            choices: Choices.US_STATES,
            validations: { required: true, maxLength: 2, minLength: 2 },
          },
          {
            source: "zip_code",
            label: "Zip Code",
            validations: { required: true },
          },
          {
            source: "country",
            label: "Country",
            type: "drop-down",
            choices: Choices.COUNTRIES,
            defaultValue: "USA",
            validations: { required: true },
          },
        ],
        components: ["History"],
      },
      {
        tab_name: "Benefit Cycles",
        backend_resource: "company_benefit_cycles",
        linking_field: "company_id",
        attributes: [
          {
            source: "start_month",
            label: "Start Month",
            choices: Choices.MONTH,
            type: "drop-down",
          },
          {
            source: "start_day",
            label: "Start Day",
            choices: Choices.DAY,
            type: "drop-down",
          },
          {
            source: "end_month",
            label: "End Month",
            choices: Choices.MONTH,
            type: "drop-down",
          },
          {
            source: "end_day",
            label: "End Day",
            choices: Choices.DAY,
            type: "drop-down",
          },
        ],
        components: ["Create", "List", "Show"],
      },
      {
        tab_name: "Selection Windows",
        backend_resource: "company_benefit_selection_windows",
        linking_field: "company_id",
        attributes: [
          {
            source: "year",
            label: "Plan Year",
            reference: [["plan_years", "plan_year_id"]],
            validations: { required: true },
          },
          {
            source: "name",
            label: "Company Benefit Cycle",
            reference: [["company_benefit_cycles", "company_benefit_cycle_id"]],
            filter_params: [["company_id", "company_id"]],
            sortable: true,
          },
          {
            source: "start_date",
            label: "Selection Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "Selection End Date",
            type: "date",
          },
          {
            source: "offering_start_date",
            label: "Offering Start Date",
            type: "date",
          },
          {
            source: "offering_end_date",
            label: "Offering End Date",
            type: "date",
          },
          {
            source: "waiting_period_waived",
            label: "Admin Waived Waiting Period",
            type: "nullable-boolean",
            nullLabel: "N/A",
          },
          {
            custom_component:
              CustomComponents.CompanyBenefitSelectionWindowProcessingManager(),
            exclude: ["Create", "Edit"],
          },
          {
            custom_component: CustomComponents.ResetPlanSelections(),
            exclude: ["Create", "Edit"],
          },
        ],
        components: ["Create", "List", "Show", "Edit"],
      },
      {
        tab_name: "Carrier Install Documents",
        view_component:
          CustomComponents.CompanyCarrierInstallDocumentUploadTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Insurance Quote Requests",
        backend_resource: "company_insurance_quote_requests",
        linking_field: "company_id",
        sort_order: "ASC",
        sort_field: "status",
        view_component: CustomComponents.InsuranceQuoteRequestFromZywaveQuote(),
        components: ["CustomView", "List", "Show"],
        attributes: [
          {
            source: "offering_start_date",
            label: "Offering Start Date",
          },
          {
            source: "offering_end_date",
            label: "Offering End Date",
          },
          {
            source: "selection_window_start_date",
            label: "Selection Window Start Date",
          },
          {
            source: "selection_window_end_date",
            label: "Selection Window End Date",
          },
          {
            custom_component:
              CustomComponents.CompanyInsuranceQuoteRequestProcessingManager(),
            exclude: ["Create"],
          },
          {
            custom_component: CustomComponents.ResetHealthApplication(),
            exclude: ["Create", "Edit"],
          },
        ],
      },
      {
        tab_name: "FSA Offerings",
        backend_resource: "company_fsa_offerings",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "FSA Enrollments",
        backend_resource: "company_fsa_enrollments",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "id",
            label: "FSA Offering",
            reference: [["company_fsa_offerings", "company_fsa_offering_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "HSA Offerings",
        backend_resource: "company_hsa_offerings",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "HSA Enrollments",
        backend_resource: "company_hsa_enrollments",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "id",
            label: "HSA Offering",
            reference: [["company_hsa_offerings", "company_hsa_offering_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "DCFSA Offerings",
        backend_resource: "company_dcfsa_offerings",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "DCFSA Enrollments",
        backend_resource: "company_dcfsa_enrollments",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "id",
            label: "DCFSA Offering",
            reference: [
              ["company_dcfsa_offerings", "company_dcfsa_offering_id"],
            ],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "Commuter Offerings",
        backend_resource: "company_commuter_offerings",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "Commuter Enrollments",
        backend_resource: "company_commuter_enrollments",
        linking_field: "company_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["List"] },
          {
            source: "name",
            validations: { required: true },
            label: "Provider",
            sortable: true,
            reference: [["providers", "provider_id"]],
          },
          {
            source: "id",
            label: "Commuter Offering",
            reference: [
              ["company_commuter_offerings", "company_commuter_offering_id"],
            ],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "Retirement Plans",
        backend_resource: "company_retirement_plans",
        linking_field: "company_id",
        attributes: [
          {
            source: "public_id",
            label: "Public ID",
          },
          {
            source: "plan_type",
            label: "Plan Type",
            sortable: true,
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            sortable: true,
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
            sortable: true,
          },
        ],
        components: ["List", "Show"],
      },
      {
        tab_name: "Retirement Offers",
        backend_resource: "company_retirement_offers",
        linking_field: "company_id",
        attributes: [
          {
            source: "status",
            label: "Status",
            sortable: true,
            exclude: ["Create"],
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            sortable: true,
          },
        ],
        components: ["List", "Show", "Create"],
      },
      Resources.InternationalCompanyEnrollments.sub_resource_config,
      Resources.InternationalCompanyOfferings.sub_resource_config,
      Resources.LifeAndDisabilityCompanyEnrollments.sub_resource_config,
      Resources.LifeAndDisabilityCompanyOfferings.sub_resource_config,
      Resources.OptumEvents.sub_resource_config,
    ],
  },
  company_benefit_cycle: {
    title: "Benefit Cycles",
    backend_resource: "company_benefit_cycles",
    record_representation: ({ start_day, start_month, end_day, end_month }) =>
      `${start_month}/${start_day} - ${end_month}/${end_day}`,
    attributes: [
      {
        source: "start_month",
        label: "Start Month",
        choices: Choices.MONTH,
        type: "drop-down",
      },
      {
        source: "start_day",
        label: "Start Day",
        choices: Choices.DAY,
        type: "drop-down",
      },
      {
        source: "end_month",
        label: "End Month",
        choices: Choices.MONTH,
        type: "drop-down",
      },
      {
        source: "end_day",
        label: "End Day",
        choices: Choices.DAY,
        type: "drop-down",
      },
    ],
    components: ["Show", "Edit"],
  },
  member: {
    exporter: Exporters.MemberExporter,
    title: "Members",
    mapped_to: [
      "noyo",
      "justworks",
      "benefits_registry",
      "justworks_payroll",
      "justworks_cdms",
    ],
    backend_resource: "members",
    resource_id_key: "member_id",
    title_identifiers: ["first_name", "last_name"],
    record_representation: ({ first_name, last_name }) =>
      `${first_name} ${last_name}`,
    filters: [
      "first_name",
      "middle_name",
      "last_name",
      "preferred_name",
      "relationship",
      "sex",
      "date_of_birth",
      "Company",
    ],
    attributes: [
      {
        custom_component: CustomComponents.MemberSetupManager(),
        exclude: ["Create", "Edit", "List"],
      },
      {
        custom_component: CustomComponents.MemberHealthInsuranceSetupManager(),
        exclude: ["Create", "Edit", "List"],
      },
      {
        custom_component: CustomComponents.ViewAsButton(),
        exclude: ["Create"],
      },
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "cdms_canonical_id", label: "Canonical ID", readonly: true },
      {
        custom_component: CustomComponents.MembersDashboardButton,
        label: "Go to:",
        exclude: ["Create", "Edit"],
      },
      {
        source: "first_name",
        validations: { required: true },
        label: "First Name",
        sortable: true,
      },
      { source: "middle_name", label: "Middle Name", sortable: true },
      {
        source: "last_name",
        validations: { required: true },
        label: "Last Name",
        sortable: true,
      },
      { source: "preferred_name", label: "Preferred Name", sortable: true },
      {
        source: "sex",
        validations: { required: true },
        label: "Sex",
        choices: Choices.SEX,
        type: "radio",
      },
      {
        source: "date_of_birth",
        validations: { required: true },
        label: "DOB",
        type: "date",
      },
      {
        source: "email_address",
        label: "email",
      },
      { source: "ssn", label: "SSN", type: "ssn", exclude: ["List"] },
      { source: "ssn", label: "SSN", exclude: ["Show", "Create", "Edit"] },
      { source: "phone_number", label: "Phone Number", type: "string" },
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        validations: { required: true },
      },
      {
        source: "ability_groups",
        label: "Ability Groups",
        options_reference: ["ability_groups", "name"],
        type: "multi-checkbox",
      },
      {
        source: "health_insurance_eligibility_date",
        label: "Health Insurance Eligibility Date",
        type: "date",
      },
      {
        source: "cdms_canonical_id",
        label: "Canonical ID",
      },
    ],
    sub_resources: [
      Resources.Dependents.sub_resource_config,
      {
        tab_name: "Plan Selections",
        backend_resource: "member_benefit_selections",
        linking_field: "member_id",
        attributes: [
          {
            source: "display_name",
            label: "Company Benefit Offerings",
            reference: [
              ["company_benefit_offerings", "company_benefit_offering_ids"],
            ],
            type: "multi-reference",
            filter_params: [["company_id", "company_id"]],
            sort: "id",
          },
          {
            source: "display_name",
            label: "Plan",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
          },
          {
            source: "coverage_start_date",
            label: "Coverage Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "selection_status",
            label: "Status",
            choices: Choices.MEMBER_BENEFIT_SELECTION_STATUS,
            type: "drop-down",
            defaultValue: "new",
            validations: { required: true },
          },
          {
            source: "medical_waiver_status",
            label: "Medical Waiver Status",
            exclude: ["Create", "Edit"],
          },
          {
            label: "Dependent IDs",
            reference: [["member_dependents", "member_dependent_ids"]],
            type: "multi-reference",
            filter_params: [["member_id", "member_id"]],
          },
          {
            source: "incomplete_enrollment_prerequisites",
            label: "Incomplete Enrollment Prerequisites",
            exclude: ["Create", "Edit"],
          },
          {
            custom_component: CustomComponents.ConvertSelectionButton(),
            exclude: ["Create", "Edit"],
          },
          {
            custom_component:
              CustomComponents.NewHireMedicalInsuranceEnrollmentManager(),
            exclude: ["Create", "Edit"],
          },
        ],
        components: ["Show", "Create", "List"],
      },
      {
        tab_name: "Selection Windows",
        backend_resource: "member_benefit_selection_windows",
        linking_field: "member_id",
        attributes: [
          {
            source: "coverage_start_date",
            label: "Coverage Start Date",
            type: "date",
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
          {
            custom_component:
              CustomComponents.MemberSelectionWindowProcessingManager(),
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "Plan Enrollments",
        backend_resource: "enrollment_states",
        linking_field: "member_id",
        read_only: true,
        timeline_identifiers: [
          {
            label: "Benefit Type",
            name: "benefit_type",
            choices: Choices.BENEFIT_TYPE,
          },
        ],
        attributes: [
          {
            source: "display_name",
            label: "Plan",
            reference: [
              [
                "company_plan_offerings",
                "enrollment_info.company_plan_offering_id",
              ],
              ["plans", "plan_id"],
            ],
            filter_params: [["company_id", "company_id"]],
          },
          { source: "benefit_type", label: "Benefit Type" },
          {
            source: "year",
            label: "Plan Year",
            reference: [["plan_years", "plan_year_id"]],
          },
          { source: "start_date", label: "Start Date" },
          { source: "end_date", label: "End Date" },
          { source: "enrollment_info.coverage_tier", label: "Coverage Tier" },
          {
            label: "Dependents",
            reference: [["member_dependents", "enrollment_info.dependent_ids"]],
            type: "multi-reference",
          },
          { source: "enrollment_info.premium_basis", label: "Premium Basis" },
          { source: "enrollment_info.premium_amount", label: "Premium Amount" },
          {
            source: "enrollment_info.member_benefit_selection_id",
            label: "Selection ID",
          },
          {
            source: "enrollment_info.ben_reg_enrollment_mapping.external_id",
            label: "Benefits Registry Data",
            type: "external_id",
            external_id_owner: "benefits_registry",
          },
          {
            source: "enrollment_info.carrier_member_id",
            label: "Carrier Member ID",
          },
        ],
        components: ["History"],
      },
      {
        tab_name: "Deductions",
        backend_resource: "billing_deductions_requests",
        linking_field: "member_id",
        sort_order: "DESC",
        read_only: true,
        attributes: [
          {
            source: "created_at",
            label: "Sent At",
            sortable: true,
          },
          {
            source: "ledger",
            label: "Ledger",
          },
          {
            source: "payload.benefit_type",
            label: "Benefit Type",
          },
          { source: "payload.premium_amount", label: "Premium" },
          {
            source: "payload.employee_contribution",
            label: "Employee Contribution",
          },
          {
            source: "payload.company_contribution",
            label: "Company Contribution",
          },
          {
            source: "payload.contribution_basis",
            label: "Contribution Basis",
          },
          { source: "payload.start_date", label: "Start Date" },
          { source: "payload.end_date", label: "End Date" },
          {
            source: "operation_type",
            label: "Operation",
          },
          { source: "response", label: "Response" },
        ],
        components: ["List", "Show"],
      },
      {
        tab_name: "Addresses",
        backend_resource: "member_addresses",
        linking_field: "member_id",
        timeline_identifiers: [
          {
            label: "Address Type",
            name: "address_type",
            choices: Choices.MEMBER_ADDRESS_TYPE_CHOICES,
          },
        ],
        attributes: [
          {
            source: "street_one",
            label: "Street Line 1",
            validations: { required: true },
          },
          {
            source: "street_two",
            label: "Street Line 2",
          },
          { source: "city", label: "City", validations: { required: true } },
          {
            source: "state",
            label: "State",
            type: "drop-down",
            choices: Choices.US_STATES,
            validations: { required: true, maxLength: 2, minLength: 2 },
          },
          {
            source: "zip_code",
            label: "Zip Code",
            validations: { required: true },
          },
          {
            source: "country",
            label: "Country",
            type: "drop-down",
            choices: Choices.COUNTRIES,
            defaultValue: "USA",
            validations: { required: true },
          },
        ],
        components: ["History"],
      },
      {
        tab_name: "Employment State",
        backend_resource: "member_employment_states",
        linking_field: "member_id",
        timeline_identifiers: [],
        attributes: [
          {
            source: "employment_status",
            label: "Status",
            choices: Choices.MEMBER_EMPLOYMENT_STATE_STATUS,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "employment_type",
            label: "Type",
            choices: Choices.MEMBER_EMPLOYMENT_STATE_TYPE,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "minutes_worked",
            label: "Hours worked",
            type: "hours",
            validations: { required: true },
          },
        ],
        components: ["History"],
      },
      {
        tab_name: "Insurance Class",
        backend_resource: "member_insurance_classes",
        linking_field: "member_id",
        attributes: [
          {
            source: "name",
            label: "Insurance Class",
            type: "drop-down",
            validations: { required: true },
            reference: [["insurance_classes", "insurance_class_id"]],
            filter_params: [["company_id", "company_id"]],
          },
        ],
        components: ["History"],
      },
      {
        tab_name: "QLEs",
        view_component: CustomComponents.MemberQleTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Terminations",
        view_component: CustomComponents.TerminationsTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "FSA Offerings",
        backend_resource: "member_fsa_offerings",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Company FSA Enrollments",
            reference: [
              ["company_fsa_enrollments", "company_fsa_enrollment_id"],
            ],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "start_date",
            label: "Company FSA Enrollments",
            reference: [
              ["company_fsa_enrollments", "company_fsa_enrollment_id"],
            ],
            type: "drop-down",
            filter_params: [["company_id", "company_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
          {
            source: "effective_date",
            label: "Effective Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "FSA Enrollments",
        backend_resource: "member_fsa_enrollments",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Member FSA Offerings",
            reference: [["member_fsa_offerings", "member_fsa_offering_id"]],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "offer_start_date",
            label: "Member FSA Offerings",
            reference: [["member_fsa_offerings", "member_fsa_offering_id"]],
            type: "drop-down",
            filter_params: [["member_id", "member_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "amount",
            label: "Amount",
            type: "number",
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "active_or_upcoming_yearly_contribution_amount",
            label: "Yearly Contribution Amount",
            type: "number",
            exclude: ["Create"],
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "DCFSA Offerings",
        backend_resource: "member_dcfsa_offerings",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Company DCFSA Enrollments",
            reference: [
              ["company_dcfsa_enrollments", "company_dcfsa_enrollment_id"],
            ],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "start_date",
            label: "Company DCFSA Enrollments",
            reference: [
              ["company_dcfsa_enrollments", "company_dcfsa_enrollment_id"],
            ],
            type: "drop-down",
            filter_params: [["company_id", "company_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
          {
            source: "effective_date",
            label: "Effective Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "DCFSA Enrollments",
        backend_resource: "member_dcfsa_enrollments",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Member DCFSA Offerings",
            reference: [["member_dcfsa_offerings", "member_dcfsa_offering_id"]],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "offer_start_date",
            label: "Member DCFSA Offerings",
            reference: [["member_dcfsa_offerings", "member_dcfsa_offering_id"]],
            type: "drop-down",
            filter_params: [["member_id", "member_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "amount",
            label: "Amount",
            type: "number",
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "active_or_upcoming_yearly_contribution_amount",
            label: "Yearly Contribution Amount",
            type: "number",
            exclude: ["Create"],
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "HSA Offerings",
        backend_resource: "member_hsa_offerings",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Company HSA Enrollments",
            reference: [
              ["company_hsa_enrollments", "company_hsa_enrollment_id"],
            ],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "start_date",
            label: "Company HSA Enrollments",
            reference: [
              ["company_hsa_enrollments", "company_hsa_enrollment_id"],
            ],
            type: "drop-down",
            filter_params: [["company_id", "company_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "HSA Enrollments",
        backend_resource: "member_hsa_enrollments",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Member HSA Offerings",
            reference: [["member_hsa_offerings", "member_hsa_offering_id"]],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "offer_start_date",
            label: "Member HSA Offerings",
            reference: [["member_hsa_offerings", "member_hsa_offering_id"]],
            type: "drop-down",
            filter_params: [["member_id", "member_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "amount",
            label: "Amount",
            type: "number",
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "active_or_upcoming_monthly_contribution_amount",
            label: "Monthly Contribution Amount",
            type: "number",
            exclude: ["Create"],
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
            validations: { required: true },
            exclude: ["Create"],
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "Commuter Offerings",
        backend_resource: "member_commuter_offerings",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
          {
            source: "id",
            label: "Company Commuter Enrollments",
            reference: [
              [
                "company_commuter_enrollments",
                "company_commuter_enrollment_id",
              ],
            ],
            sort: "id",
            exclude: ["Create", "Edit"],
          },
          {
            source: "start_date",
            label: "Company Commuter Enrollments",
            reference: [
              [
                "company_commuter_enrollments",
                "company_commuter_enrollment_id",
              ],
            ],
            type: "drop-down",
            filter_params: [["company_id", "company_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "offer_start_date",
            label: "Offer Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "offer_end_date",
            label: "Offer End Date",
            type: "date",
          },
        ],
        components: ["Show", "Edit", "Create", "List"],
      },
      {
        tab_name: "Commuter Enrollments",
        backend_resource: "member_commuter_enrollments",
        linking_field: "member_id",
        attributes: [
          { source: "id", label: "ID", exclude: ["List"] },
          {
            source: "id",
            label: "Member Commuter Offerings",
            reference: [
              ["member_commuter_offerings", "member_commuter_offering_id"],
            ],
            sort: "id",
          },
          {
            source: "start_date",
            label: "Company Commuter Enrollments",
            reference: [
              [
                "company_commuter_enrollments",
                "company_commuter_enrollment_id",
              ],
            ],
            type: "drop-down",
            filter_params: [["company_id", "company_id"]],
            validations: { required: true },
            exclude: ["List"],
          },
          {
            source: "status",
            label: "Status",
            choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
            type: "drop-down",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
        ],
        components: ["Show", "List"],
      },
      Resources.MemberRetirementOfferings.sub_resource_config,
      Resources.MemberRetirementEnrollments.sub_resource_config,
      {
        tab_name: "Retirement Contributions",
        backend_resource: "member_retirement_contributions",
        linking_field: "member_id",
        attributes: [
          {
            source: "public_id",
            label: "Public ID",
            exclude: ["Create", "Edit", "Delete"],
          },
          {
            source: "start_date",
            label: "Start Date",
            exclude: ["Create", "Edit", "Delete"],
          },
          {
            source: "end_date",
            label: "End Date",
            exclude: ["Create", "Edit", "Delete"],
          },
          {
            source: "deduction_id",
            label: "Payroll deduction ID",
            exclude: ["Create", "Edit", "Delete"],
          },
        ],
        components: ["List", "Show"],
      },
      Resources.InternationalMemberEnrollments.sub_resource_config,
      Resources.InternationalMemberOfferings.sub_resource_config,
    ],
    components: ["Show", "Create", "Edit", "List"],
  },
  provider: {
    title: "Providers",
    backend_resource: "providers",
    record_representation: "name",
    mapped_to: ["noyo", "ideon"],
    filters: ["name"],
    attributes: [
      {
        source: "id",
        label: "ID",
        exclude: ["Create", "Edit", "List"],
      },
      {
        source: "name",
        validations: { required: true },
        label: "Name",
        sortable: true,
      },
    ],
    components: ["Show", "Create", "Edit", "List"],
  },
  company_provider_connection: {
    title: "Provider Connections",
    backend_resource: "company_provider_connections",
    record_representation: "name",
    mapped_to: ["noyo"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "name",
        validations: { required: true },
        label: "Company",
        sortable: true,
        reference: [["companies", "company_id"]],
      },
      {
        source: "provider_company_id",
        validations: { required: true },
        label: "Provider Company ID",
      },
      {
        source: "year",
        label: "Last Enrolled Plan Year",
        reference: [["plan_years", "last_enrolled_plan_year_id"]],
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  company_carrier_install_documents: {
    backend_resource: "company_carrier_install_documents",
    title: " > Carrier Install Documents",
    linking_field: "company_id",
    attributes: [
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        validations: { required: true },
        readonly: true,
      },
      {
        source: "label",
        label: "Document Type",
        reference: [
          [
            "company_carrier_install_document_types",
            "company_carrier_install_document_type_id",
          ],
        ],
        type: "drop-down",
      },
      {
        source: "label",
        label: "Category",
        reference: [
          [
            "company_carrier_install_document_categories",
            "company_carrier_install_document_category_id",
          ],
        ],
        type: "drop-down",
      },
      { source: "approved", label: "Approved?", type: "boolean" },
      {
        custom_component:
          CustomComponents.CompanyCarrierInstallDocumentDownloadTab(),
      },
    ],
    allow_delete: true,
    components: ["Edit", "Show"],
  },
  company_benefit_offering: {
    title: "Company Offerings",
    record_representation: "display_name",
    backend_resource: "company_benefit_offerings",
    mapped_to: ["noyo", "benefits_registry"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        sortable: true,
        readonly: true,
      },
      {
        source: "name",
        label: "Company Benefit Cycle",
        reference: [["company_benefit_cycles", "company_benefit_cycle_id"]],
        filter_params: [["company_id", "company_id"]],
        sortable: true,
      },
      {
        source: "offering_start_date",
        label: "Company Benefit Selection Window",
        reference: [
          [
            "company_benefit_selection_windows",
            "company_benefit_selection_window_id",
          ],
        ],
        filter_params: [["company_id", "company_id"]],
      },
      {
        source: "benefit_type",
        label: "Benefit Type",
        choices: Choices.BENEFIT_TYPE,
        type: "drop-down",
        readonly: true,
      },
      {
        source: "name",
        label: "Providers",
        reference: [["providers", "provider_ids"]],
        type: "multi-reference",
      },
      {
        source: "offering_start_date",
        label: "Offering Start Date",
        type: "date",
      },
      {
        source: "offering_end_date",
        label: "Offering End Date",
        type: "date",
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.BENEFIT_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        custom_component: CustomComponents.RolloverButton(),
        exclude: ["Edit"],
      },
      {
        custom_component: CustomComponents.DownloadPriceGridsButton(),
        exclude: ["Edit"],
      },
    ],
    components: ["Show", "Edit"],
    sub_resources: [
      {
        tab_name: "Price Grid Upload",
        view_component: CustomComponents.PriceGridUploadTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Price Grid Documents",
        backend_resource: "documents",
        linking_field: "documentable_id",
        attributes: [
          { source: "display_name", label: "Display Name" },
          { source: "file_name", label: "File Name" },
          { source: "content_type", label: "Content Type" },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "Provider Offerings",
        backend_resource: "company_benefit_offering_providers",
        linking_field: "company_benefit_offering_id",
        attributes: [
          {
            source: "display_name",
            label: "Name",
            exclude: ["Create"],
          },
          {
            source: "name",
            label: "Provider",
            reference: [["providers", "provider_id"]],
            exclude: ["Show", "List"],
          },
          {
            source: "display_name",
            label: "Plan Offerings",
            reference: [["plans", "plan_ids"]],
            type: "multi-reference",
            filter_params: [
              ["benefit_type", "benefit_type"],
              ["plan_year_id", "plan_year_id"],
            ],
            sort_params: ["name", "ASC"],
          },
        ],
        components: ["Show", "List"],
      },
    ],
  },
  company_fsa_offering: {
    title: "Company FSA Offerings",
    backend_resource: "company_fsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  company_fsa_enrollment: {
    title: "Company FSA Enrollments",
    backend_resource: "company_fsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "id",
        label: "FSA Offering",
        reference: [["company_fsa_offerings", "company_fsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
        validations: { required: true },
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberFsaEnrollments.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  company_hsa_offering: {
    title: "Company HSA Offerings",
    backend_resource: "company_hsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  company_hsa_enrollment: {
    title: "Company HSA Enrollments",
    backend_resource: "company_hsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "id",
        label: "HSA Offering",
        reference: [["company_hsa_offerings", "company_hsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
        validations: { required: true },
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [
      Resources.CompanyHsaContributionSchemes.sub_resource_config,
      Resources.MemberHsaEnrollments.sub_resource_config,
    ],
    components: ["Show", "Edit", "Create"],
  },
  company_dcfsa_offering: {
    title: "Company DCFSA Offerings",
    backend_resource: "company_dcfsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  company_dcfsa_enrollment: {
    title: "Company DCFSA Enrollments",
    backend_resource: "company_dcfsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "id",
        label: "DCFSA Offering",
        reference: [["company_dcfsa_offerings", "company_dcfsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
        validations: { required: true },
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberDcfsaEnrollments.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  company_commuter_offering: {
    title: "Company Commuter Offerings",
    backend_resource: "company_commuter_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  company_commuter_enrollment: {
    title: "Company Commuter Enrollments",
    backend_resource: "company_commuter_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        validations: { required: true },
        label: "Provider",
        sortable: true,
        reference: [["providers", "provider_id"]],
      },
      {
        source: "id",
        label: "Commuter Offering",
        reference: [
          ["company_commuter_offerings", "company_commuter_offering_id"],
        ],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberCommuterEnrollments.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  company_retirement_offer: {
    title: "Company Retirement Offers",
    backend_resource: "company_retirement_offers",
    mapped_to: ["benefits_registry"],
    attributes: [
      {
        source: "id",
        label: "ID",
        readonly: true,
      },
      {
        source: "name",
        readonly: true,
        label: "Company",
        reference: [["companies", "company_id"]],
      },
      {
        source: "start_date",
        validations: { required: true },
        label: "Start Date",
      },
      {
        source: "status",
        validations: { required: true },
        label: "Status",
        choices: Choices.COMPANY_RETIREMENT_OFFER_STATUS_CHOICES,
        type: "drop-down",
      },
    ],
    components: ["Show", "Edit"],
  },
  company_retirement_plan: {
    title: "Company Retirement Plans",
    backend_resource: "company_retirement_plans",
    mapped_to: ["benefits_registry"],
    attributes: [
      {
        source: "name",
        validations: { required: true },
        label: "Company",
        reference: [["companies", "company_id"]],
      },
      {
        source: "id",
        label: "ID",
        exclude: ["Create", "Edit", "List", "Delete"],
      },
    ],
    components: ["Show"],
    sub_resources: [
      {
        tab_name: "Plan Amendments",
        backend_resource: "company_retirement_plan_amendments",
        linking_field: "company_retirement_plan_id",
        attributes: [
          {
            source: "public_id",
            label: "Public ID",
            reference: [["company_retirement_plan_amendments", "id"]],
            exclude: ["Create", "Edit", "Delete"],
          },
          {
            source: "start_date",
            label: "Start Date",
            reference: [["company_retirement_plan_amendments", "id"]],
            exclude: ["Create", "Edit", "Delete"],
            sortable: true,
          },
          {
            source: "end_date",
            label: "End Date",
            reference: [["company_retirement_plan_amendments", "id"]],
            exclude: ["Create", "Edit", "Delete"],
            sortable: true,
          },
        ],
        components: ["Show", "List"],
      },
    ],
  },
  company_retirement_plan_amendment: {
    title: "Company Retirement Plan Amendments",
    backend_resource: "company_retirement_plan_amendments",
    attributes: [
      {
        source: "id",
        label: "Company Retirement Plan",
        reference: [["company_retirement_plans", "company_retirement_plan_id"]],
      },
      {
        source: "match",
        label: "Match",
        type: "json",
      },
      {
        source: "non_elective_contribution",
        label: "Nonelective Contribution",
        type: "json",
      },
    ],
    components: ["Show"],
  },
  member_retirement_offering:
    Resources.MemberRetirementOfferings.top_level_resource_config,
  member_retirement_enrollment:
    Resources.MemberRetirementEnrollments.top_level_resource_config,
  member_retirement_contribution: {
    title: "Member Retirement Contributions",
    backend_resource: "member_retirement_contributions",
    attributes: [
      {
        source: "first_name",
        validations: { required: true },
        label: "Member",
        reference: [["members", "member_id"]],
      },
      {
        source: "id",
        label: "ID",
        exclude: ["Create", "Edit", "List", "Delete"],
      },
    ],
    components: ["Show"],
    sub_resources: [
      {
        tab_name: "Contribution Details",
        backend_resource: "member_retirement_contribution_details",
        linking_field: "member_retirement_contribution_id",
        attributes: [
          {
            source: "public_id",
            label: "Public ID",
            reference: [["member_retirement_contribution_details", "id"]],
            exclude: ["Create", "Edit", "Delete"],
          },
          {
            source: "start_date",
            label: "Start Date",
            reference: [["member_retirement_contribution_details", "id"]],
            exclude: ["Create", "Edit", "Delete"],
            sortable: true,
          },
          {
            source: "end_date",
            label: "End Date",
            reference: [["member_retirement_contribution_details", "id"]],
            exclude: ["Create", "Edit", "Delete"],
            sortable: true,
          },
        ],
        components: ["Show", "List"],
      },
    ],
  },
  member_retirement_contribution_detail: {
    title: "Member Retirement Contribution Details",
    backend_resource: "member_retirement_contribution_details",
    attributes: [
      {
        source: "public_id",
        label: "Public ID",
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "start_date",
        label: "Start Date",
        reference: [["member_retirement_contribution_details", "id"]],
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "end_date",
        label: "End Date",
        reference: [["member_retirement_contribution_details", "id"]],
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "amount",
        label: "Amount",
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "basis",
        label: "Basis",
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "effective_match",
        label: "Effective Match",
        type: "json",
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "deleted_at",
        label: "Deleted At",
        exclude: ["Create", "Edit", "Delete"],
      },
      {
        source: "external_contributions",
        label: "External Contributions",
        exclude: ["Create", "Edit", "Delete"],
      },
    ],
    components: ["Show"],
  },
  member_fsa_offering: {
    title: "Member FSA Offerings",
    backend_resource: "member_fsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "start_date",
        label: "Company FSA Enrollments",
        reference: [["company_fsa_enrollments", "company_fsa_enrollment_id"]],
        type: "drop-down",
        filter_params: [["company_id", "company_id"]],
        validations: { required: true },
        exclude: ["List"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
      {
        source: "effective_date",
        label: "Effective Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  member_fsa_enrollment: {
    title: "Member FSA Enrollments",
    backend_resource: "member_fsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "id",
        label: "FSA Offering",
        reference: [["member_fsa_offerings", "member_fsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberFsaContributions.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  member_fsa_contribution:
    Resources.MemberFsaContributions.top_level_resource_config,
  member_dcfsa_offering: {
    title: "Member DCFSA Offerings",
    backend_resource: "member_dcfsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "start_date",
        label: "Company DCFSA Enrollments",
        reference: [
          ["company_dcfsa_enrollments", "company_dcfsa_enrollment_id"],
        ],
        type: "drop-down",
        filter_params: [["company_id", "company_id"]],
        validations: { required: true },
        exclude: ["List"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
      {
        source: "effective_date",
        label: "Effective Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  member_dcfsa_enrollment: {
    title: "Member DCFSA Enrollments",
    backend_resource: "member_dcfsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "id",
        label: "DCFSA Offering",
        reference: [["member_dcfsa_offerings", "member_dcfsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberDcfsaContributions.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  member_dcfsa_contribution:
    Resources.MemberDcfsaContributions.top_level_resource_config,
  member_hsa_offering: {
    title: "Member HSA Offerings",
    backend_resource: "member_hsa_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "start_date",
        label: "Company HSA Enrollments",
        reference: [["company_hsa_enrollments", "company_hsa_enrollment_id"]],
        type: "drop-down",
        filter_params: [["company_id", "company_id"]],
        validations: { required: true },
        exclude: ["List"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  member_hsa_enrollment: {
    title: "Member HSA Enrollments",
    backend_resource: "member_hsa_enrollments",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "id",
        label: "HSA Offering",
        reference: [["member_hsa_offerings", "member_hsa_offering_id"]],
        exclude: ["Create", "Edit"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberHsaContributions.sub_resource_config],
    components: ["Show", "Edit", "Create"],
  },
  member_hsa_contribution:
    Resources.MemberHsaContributions.top_level_resource_config,
  member_commuter_offering: {
    title: "Member Commuter Offerings",
    backend_resource: "member_commuter_offerings",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "start_date",
        label: "Company Commuter Enrollments",
        reference: [
          ["company_commuter_enrollments", "company_commuter_enrollment_id"],
        ],
        type: "drop-down",
        filter_params: [["company_id", "company_id"]],
        validations: { required: true },
        exclude: ["List"],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_OFFERING_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "offer_start_date",
        label: "Offer Start Date",
        type: "date",
      },
      {
        source: "offer_end_date",
        label: "Offer End Date",
        type: "date",
      },
    ],
    components: ["Show", "Edit", "Create"],
  },
  member_commuter_enrollment: {
    title: "Member Commuter Enrollments",
    backend_resource: "member_commuter_enrollments",
    attributes: [
      { source: "id", label: "ID" },
      {
        source: "id",
        label: "Commuter Offering",
        reference: [
          ["member_commuter_offerings", "member_commuter_offering_id"],
        ],
      },
      {
        source: "status",
        label: "Status",
        choices: Choices.MEMBER_OPTUM_ENROLLMENT_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "start_date",
        label: "Enrollment Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Enrollment End Date",
        type: "date",
      },
    ],
    sub_resources: [Resources.MemberCommuterOrders.sub_resource_config],
    components: ["Show"],
  },
  member_commuter_order:
    Resources.MemberCommuterOrders.top_level_resource_config,
  company_benefit_offering_provider: {
    title: "Provider Offerings",
    backend_resource: "company_benefit_offering_providers",
    record_representation: "display_name",
    attributes: [
      {
        source: "display_name",
        label: "Name",
        readonly: true,
      },
      {
        source: "display_name",
        label: "Plan Offerings",
        reference: [["plans", "plan_ids"]],
        type: "multi-reference",
        filter_params: [
          ["provider_id", "provider_id"],
          ["benefit_type", "benefit_type"],
          ["plan_year_id", "plan_year_id"],
        ],
      },
    ],
    components: ["Show", "Edit"],
    sub_resources: [
      {
        tab_name: "Plan Offerings",
        backend_resource: "company_plan_offerings",
        linking_field: "company_benefit_offering_provider_id",
        attributes: [
          {
            source: "display_name",
            label: "Provider Offering",
            reference: [
              [
                "company_benefit_offering_providers",
                "company_benefit_offering_provider_id",
              ],
            ],
            exclude: ["Create"],
          },
          {
            source: "display_name",
            label: "Provider Offering",
            readonly: true,
            exclude: ["Show", "List"],
          },
          {
            source: "plan_name",
            label: "Plan",
            exclude: ["Create"],
          },
          {
            source: "display_name",
            label: "Plan",
            reference: [["plans", "plan_id"]],
            filter_params: [
              ["provider_id", "provider_id"],
              ["benefit_type", "benefit_type"],
              ["plan_year_id", "plan_year_id"],
            ],
            exclude: ["Show", "List"],
          },
          {
            source: "selection_status",
            label: "Status",
            choices: Choices.COMPANY_PLAN_OFFERING_SELECTION_STATUS,
            type: "drop-down",
            defaultValue: "confirmed",
          },
        ],
        components: ["Show", "List", "Create"],
      },
    ],
  },
  company_plan: {
    title: "Company Plans",
    backend_resource: "company_plan_offerings",
    record_representation: "display_name",
    mapped_to: ["noyo"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        sortable: true,
        exclude: ["Edit"],
      },
      {
        source: "display_name",
        label: "Plan Name",
        reference: [["plans", "plan_id"]],
        sortable: true,
      },
      {
        source: "name",
        label: "Plan Code",
        reference: [["plans", "plan_id"]],
        sortable: true,
      },
      {
        source: "display_name",
        label: "Provider Offering",
        reference: [
          [
            "company_benefit_offering_providers",
            "company_benefit_offering_provider_id",
          ],
        ],
        sortable: true,
      },
      {
        source: "selection_status",
        label: "Status",
        choices: Choices.COMPANY_PLAN_OFFERING_SELECTION_STATUS,
        type: "drop-down",
        defaultValue: "confirmed",
      },
    ],
    components: ["Show", "Edit"],
    sub_resources: [
      {
        tab_name: "Contributions",
        backend_resource: "company_plan_offering_contributions",
        linking_field: "company_plan_offering_id",
        parent_fields: ["company_id"],
        attributes: [
          {
            source: "display_name",
            label: "Plan Offering",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
            exclude: ["Show", "List"],
          },
          {
            source: "display_name",
            label: "Plan Offering",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [["company_id", "company_id"]],
            exclude: ["Create", "Edit"],
          },
          {
            source: "name",
            label: "Company",
            reference: [["companies", "company_id"]],
            exclude: ["Create", "Edit"],
          },
          {
            source: "name",
            label: "Insurance Class",
            reference: [["insurance_classes", "insurance_class_id"]],
            filter_params: [["company_id", "company_id"]],
          },
          {
            source: "mode",
            label: "Mode",
            choices: Choices.CONTRIBUTION_MODE,
            type: "drop-down",
          },
          {
            source: "display_name",
            label: "Reference Plan Offering",
            reference: [
              ["company_plan_offerings", "reference_company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
          },
          {
            source: "employee_contribution",
            label: "Employee Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_spouse_contribution",
            label: "Spouse Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_children_contribution",
            label: "Children Tier",
            type: "contribution",
          },
          {
            source: "employee_plus_family_contribution",
            label: "Family Tier",
            type: "contribution",
          },
        ],
        components: ["Show", "Create", "List"],
      },
      {
        tab_name: "Price Grids",
        backend_resource: "price_grids",
        linking_field: "company_plan_offering_id",
        attributes: [
          {
            source: "created_at",
            label: "Created At",
            sortable: true,
            readonly: true,
          },
          {
            source: "display_name",
            label: "Plan Offering",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [["company_id", "company_id"]],
            exclude: ["Show", "List"],
          },
          {
            source: "display_name",
            label: "Plan Offering",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [["company_id", "company_id"]],
            exclude: ["Create", "Edit"],
          },
          {
            source: "rate_type",
            label: "Rate Type",
          },
          {
            source: "finalized",
            label: "Finalized",
            type: "boolean",
            sortable: true,
          },
        ],
        components: ["List", "Show"],
      },
    ],
  },
  plan: {
    title: "Plans",
    backend_resource: "plans",
    record_representation: "display_name",
    mapped_to: ["beam"],
    filters: ["benefit_type", "name"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        custom_component: CustomComponents.BulkPlanUploadButton(),
        label: "Go to:",
        exclude: ["Edit", "Show", "List"],
      },
      {
        source: "benefit_type",
        label: "Benefit Type",
        choices: Choices.BENEFIT_TYPE,
        type: "drop-down",
        validations: { required: true },
      },
      {
        source: "year",
        label: "Plan Year",
        reference: [["plan_years", "plan_year_id"]],
        sort_params: ["start_date", "DESC"],
        validations: { required: true },
      },
      {
        source: "name",
        label: "Provider",
        reference: [["providers", "provider_id"]],
        sortable: true,
        sort_params: ["name", "ASC"],
        validations: { required: true },
      },
      {
        source: "name",
        label: "Code",
        sortable: true,
      },
      {
        source: "enrollment_method",
        label: "Enrollment Method",
        choices: Choices.ENROLLMENT_METHOD,
        type: "drop-down",
        validations: { required: false },
      },
      {
        source: "display_name",
        validations: { required: true },
        label: "Display Name",
        sortable: true,
      },
      {
        source: "metadata",
        label: "Metadata Present?",
        type: "conditional",
        sortable: true,
        condition: {
          field: "metadata",
          statement: "Object.keys(record[field]).length > 0",
        },
        exclude: ["Create", "Edit", "Show"],
      },
      {
        source: "plan_documents",
        label: "Plan Documents Present?",
        type: "conditional",
        sortable: true,
        condition: {
          field: "plan_documents",
          statement: "record[field].length > 0",
        },
        exclude: ["Create", "Edit", "Show"],
      },
      {
        source: "state",
        label: "State",
        type: "drop-down",
        choices: Choices.US_STATES,
        validations: { maxLength: 2, minLength: 2 },
      },
      {
        custom_component: CustomComponents.PlanMetadataShow(),
        exclude: ["Create", "Edit", "List"],
      },
      {
        custom_component: CustomComponents.PlanMetadataEdit(),
        exclude: ["Create", "Show", "List"],
      },
      {
        custom_component: CustomComponents.PlanMetadataCreate(),
        exclude: ["Edit", "Show", "List"],
      },
    ],
    sub_resources: [
      {
        tab_name: "Documents",
        backend_resource: "plan_documents",
        linking_field: "plan_id",
        attributes: [
          { source: "display_name", label: "Display Name" },
          { source: "file_name", label: "File Name" },
          { source: "content_type", label: "Content Type" },
          {
            source: "created_at",
            label: "Date Created",
            sortable: true,
          },
          {
            custom_component: CustomComponents.PlanDocumentDownloadTab(),
            exclude: ["Create", "Edit"],
          },
        ],
        components: ["Show", "List"],
      },
      {
        tab_name: "Upload",
        view_component: CustomComponents.PlanDocumentUploadTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Zywave Mapping",
        backend_resource: "plan_zywave_details",
        linking_field: "plan_id",
        attributes: [
          {
            source: "state",
            label: "State",
            choices: Choices.US_STATES,
            type: "drop-down",
          },
          { source: "is_default", label: "Default", type: "boolean" },
          {
            custom_component: CustomComponents.ZywavePlansInput(),
            exclude: ["Show", "List"],
          },
          {
            source: "external_plan_names",
            label: "Zywave Plan Names",
            exclude: ["Create", "Edit"],
          },
        ],
        components: ["Show", "List", "Create", "Edit"],
      },
    ],
    components: ["Show", "List", "Edit", "Create"],
  },
  plan_document: {
    title: "Plan Documents",
    backend_resource: "plan_documents",
    filters: ["display_name", "file_name", "content_type"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "display_name", label: "Display Name" },
      { source: "file_name", label: "File Name" },
      { source: "content_type", label: "Content Type" },
      { source: "created_at", label: "Date Created", sortable: true },
      {
        custom_component: CustomComponents.PlanDocumentDownloadTab(),
        exclude: ["Create", "Edit"],
      },
    ],
    components: ["Show", "List"],
  },
  plan_zywave_detail: {
    backend_resource: "plan_zywave_details",
    attributes: [
      {
        source: "state",
        label: "State",
        choices: Choices.US_STATES,
        type: "drop-down",
      },
      { source: "is_default", label: "Default", type: "boolean" },
      {
        custom_component: CustomComponents.ZywavePlansInput(),
        exclude: ["Show", "List"],
      },
      {
        source: "external_plan_names",
        label: "Zywave Plan Names",
        exclude: ["Create", "Edit"],
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  price_grid: {
    title: "Price Grids",
    backend_resource: "price_grids",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "created_at",
        label: "Created At",
        sortable: true,
        readonly: true,
      },
      {
        source: "display_name",
        label: "Plan",
        reference: [
          ["company_plan_offerings", "company_plan_offering_id"],
          ["plans", "plan_id"],
        ],
        readonly: true,
      },
      {
        source: "rate_type",
        label: "Rate Type",
        readonly: true,
      },
      {
        source: "finalized",
        label: "Finalized",
        sortable: true,
        type: "boolean",
      },
      {
        custom_component: CustomComponents.PriceGrid(),
        exclude: ["Create", "List"],
      },
    ],
    components: ["Show", "Edit"],
  },
  member_benefit_selection: {
    title: "Member Selections",
    backend_resource: "member_benefit_selections",
    mapped_to: ["noyo", "benefits_registry"],
    attributes: [
      {
        custom_component:
          CustomComponents.NewHireMedicalInsuranceEnrollmentManager(),
        exclude: ["Create", "Edit", "List"],
      },
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "first_name",
        label: "Member",
        reference: [["members", "member_id"]],
        sortable: true,
        readonly: true,
      },
      {
        source: "display_name",
        label: "Company Benefit Offerings",
        reference: [
          ["company_benefit_offerings", "company_benefit_offering_ids"],
        ],
        type: "multi-reference",
        filter_params: [["company_id", "company_id"]],
        sort: "id",
      },
      {
        source: "display_name",
        label: "Plan",
        reference: [
          ["company_plan_offerings", "company_plan_offering_id"],
          ["plans", "plan_id"],
        ],
        filter_params: [
          ["company_id", "company_id"],
          ["selection_status", "confirmed"],
        ],
        sortable: true,
      },
      {
        source: "coverage_start_date",
        label: "Coverage Start Date",
        validations: { required: true },
      },
      {
        source: "start_date",
        label: "Start Date",
        validations: { required: true },
      },
      {
        source: "end_date",
        label: "End Date",
        validations: { required: true },
      },
      {
        source: "selection_status",
        label: "Status",
        choices: Choices.MEMBER_BENEFIT_SELECTION_STATUS,
        type: "drop-down",
        validations: { required: true },
      },
      {
        source: "first_name",
        label: "Dependents",
        reference: [["member_dependents", "member_dependent_ids"]],
        type: "multi-reference",
        filter_params: [["member_id", "member_id"]],
      },
      {
        source: "incomplete_enrollment_prerequisites",
        label: "Incomplete Enrollment Prerequisites",
        exclude: ["Create", "Edit"],
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  member_benefit_enrollment: {
    title: "Member Enrollments",
    backend_resource: "enrollment_states",
    mapped_to: ["noyo", "benefits_registry"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "member_id", label: "Member ID", exclude: ["Edit"] },
      { source: "benefit_type", label: "Benefit Type", exclude: ["Edit"] },
      {
        source: "year",
        label: "Plan Year",
        reference: [["plan_years", "plan_year_id"]],
        exclude: ["Edit"],
      },
      {
        source: "display_name",
        label: "Plan",
        reference: [
          [
            "company_plan_offerings",
            "enrollment_info.company_plan_offering_id",
          ],
          ["plans", "plan_id"],
        ],
        filter_params: [["company_id", "company_id"]],
        sortable: true,
        exclude: ["Edit"],
      },
      { source: "start_date", label: "Start Date", exclude: ["Edit"] },
      { source: "end_date", label: "End Date", exclude: ["Edit"] },
      {
        source: "enrollment_info.coverage_tier",
        label: "Coverage Tier",
        exclude: ["Edit"],
      },
      {
        label: "Dependents",
        reference: [["member_dependents", "enrollment_info.dependent_ids"]],
        type: "multi-reference",
        exclude: ["Edit"],
      },
      {
        source: "enrollment_info.premium_basis",
        label: "Premium Basis",
        exclude: ["Edit"],
      },
      {
        source: "enrollment_info.premium_amount",
        label: "Premium Amount",
        exclude: ["Edit"],
      },
      {
        source: "enrollment_info.member_benefit_selection_id",
        label: "Selection ID",
        exclude: ["Edit"],
      },
      {
        source: "enrollment_info.carrier_member_id",
        label: "Carrier Member ID",
      },
    ],
    components: ["Show", "Edit"],
  },
  plan_year: {
    title: "Plan Year",
    backend_resource: "plan_years",
    record_representation: "year",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "start_date", label: "Start Date" },
      { source: "end_date", label: "End Date" },
    ],
    components: ["Show", "Create", "Edit"],
  },
  insurance_class: {
    title: "Insurance Classes",
    backend_resource: "insurance_classes",
    record_representation: "name",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        sortable: true,
      },
      { source: "name", label: "Name" },
      {
        source: "waiting_period",
        label: "Waiting Period",
        type: "number",
      },
      {
        source: "disabled_on",
        label: "Disabled (hidden) on",
        type: "date",
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  company_contribution: {
    title: "Company Contributions",
    backend_resource: "company_plan_offering_contributions",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "display_name",
        label: "Plan Offering",
        reference: [
          ["company_plan_offerings", "company_plan_offering_id"],
          ["plans", "plan_id"],
        ],
        filter_params: [
          ["company_id", "company_id"],
          ["selection_status", "confirmed"],
        ],
      },
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        sortable: true,
      },
      {
        source: "name",
        label: "Insurance Class",
        reference: [["insurance_classes", "insurance_class_id"]],
        filter_params: [["company_id", "company_id"]],
      },
      {
        source: "mode",
        label: "Mode",
        choices: Choices.CONTRIBUTION_MODE,
        type: "drop-down",
      },
      {
        source: "display_name",
        label: "Reference Plan Offering",
        reference: [
          ["company_plan_offerings", "reference_company_plan_offering_id"],
          ["plans", "plan_id"],
        ],
        filter_params: [
          ["company_id", "company_id"],
          ["selection_status", "confirmed"],
        ],
      },
      {
        source: "employee_contribution",
        label: "Employee Tier",
        type: "contribution",
      },
      {
        source: "employee_plus_spouse_contribution",
        label: "Spouse Tier",
        type: "contribution",
      },
      {
        source: "employee_plus_children_contribution",
        label: "Children Tier",
        type: "contribution",
      },
      {
        source: "employee_plus_family_contribution",
        label: "Family Tier",
        type: "contribution",
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  clockwork_sync: {
    title: "Clockwork Sync",
    backend_resource: "clockwork_sync",
    components: [],
    custom_views: {
      list: CustomComponents.ClockWorkSync,
    },
  },
  census: {
    title: "Censuses",
    backend_resource: "company_censuses",
    components: ["Show"],
    read_only: true,
    mapped_to: ["zywave_quote", "beam_quote"],
    attributes: [
      {
        source: "census_rows",
        label: "Census Rows",
        type: "json",
      },
    ],
    sub_resources: [
      {
        tab_name: "Quotes",
        view_component: CustomComponents.QuotesTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Price Comparison",
        view_component: CustomComponents.PriceComparisonTab(),
        components: ["CustomView"],
      },
      {
        tab_name: "Large Group Quoted Premium",
        backend_resource: "large_group_quoted_premium",
        linking_field: "company_census_id",
        attributes: [
          {
            source: "large_group_plan_year",
            label: "Large Group Plan Year",
          },
          {
            source: "quoted_monthly_premium",
            label: "Quoted Monthly Premium",
          },
        ],
        components: ["List"],
      },
    ],
  },
  internal_user: {
    title: "Internal Users",
    title_identifiers: ["email"],
    record_representation: "email",
    backend_resource: "internal_users",
    filters: ["email"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "email", label: "Email Address" },
      { source: "first_name", label: "First Name" },
      { source: "last_name", label: "Last Name" },
      { source: "password", label: "Password" },
    ],
    components: ["Show", "Create", "List", "Edit"],
  },
  enrollment_modification: {
    title: "Enrollment Modifications",
    backend_resource: "enrollment_modifications",
    mapped_to: ["noyo"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "id",
        label: "Member ID",
        reference: [["members", "member_id"]],
      },
      { source: "event_type", label: "Event Type" },
      { source: "event_date", type: "date", label: "Event Date" },
      { source: "event_data", type: "json", label: "Event Data" },
      { source: "coverage_modifcations", label: "Requested Modifications" },
    ],
    components: ["Show", "Create", "Edit"],
  },
  qualifying_life_event: {
    title: "Qualifying Life Event",
    backend_resource: "qualifying_life_events",
    mapped_to: ["noyo"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "event_type", label: "Event Type" },
      { source: "event_date", type: "date", label: "Event Date" },
      {
        source: "status",
        label: "Status",
        choices: Choices.QLE_STATUS_CHOICES,
        type: "drop-down",
      },
      {
        source: "selected_actions.medical.selected_action",
        label: "Selected Action",
      },
    ],
    components: ["Show", "Create", "Edit"],
  },
  member_employment_state: {
    title: "Employment States",
    backend_resource: "member_employment_states",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "start_date", type: "date", label: "Start Date" },
      { source: "end_date", type: "date", label: "End Date" },
      { source: "employment_status", label: "Status" },
      { source: "employment_type", label: "Type" },
      { source: "minutes_worked", label: "Minutes Worked" },
    ],
    components: ["Show"],
  },
  member_address: {
    title: "Member Addresses",
    backend_resource: "member_addresses",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "address_type", label: "Type" },
      { source: "street_one", label: "Street Line 1" },
      { source: "street_two", label: "Street Line 2" },
      { source: "city", label: "City" },
      { source: "state", label: "State" },
      { source: "zip_code", label: "Zip Code" },
      { source: "country", label: "Country" },
      { source: "start_date", type: "date", label: "Start Date" },
    ],
    components: ["Show"],
  },
  company_address: {
    title: "Company Addresses",
    backend_resource: "company_addresses",
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      { source: "address_type", label: "Type" },
      { source: "street_one", label: "Street Line 1" },
      { source: "street_two", label: "Street Line 2" },
      { source: "city", label: "City" },
      { source: "state", label: "State" },
      { source: "zip_code", label: "Zip Code" },
      { source: "country", label: "Country" },
      { source: "start_date", type: "date", label: "Start Date" },
    ],
    components: ["Show"],
  },
  event: {
    title: "Events",
    backend_resource: "events",
    components: ["Show", "List"],
    filters: ["event_name", "topic"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "created_at",
        label: "Created At",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "event_name",
        label: "Event Name",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "topic",
        label: "Topic",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "acked_by",
        label: "Acknowledged By",
        type: "json",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "data",
        label: "Data",
        type: "json",
        sortable: true,
        validations: { required: true },
      },
    ],
  },
  document: {
    title: "Documents",
    backend_resource: "documents",
    components: ["Show", "List"],
    filters: ["display_name", "file_name", "content_type"],
    attributes: [
      { source: "id", label: "ID", exclude: ["Create", "Edit", "List"] },
      {
        source: "display_name",
        label: "Display Name",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "file_name",
        label: "File Name",
        sortable: true,
        validations: { required: true },
      },
      {
        source: "content_type",
        label: "Type",
        sortable: true,
        validations: { required: true },
      },
      {
        custom_component: CustomComponents.DocumentDownloadTab(),
        exclude: ["Create", "Edit"],
      },
    ],
  },
  workflow_run: {
    title: "Workflow Runs",
    backend_resource: "workflow_runs",
    title_identifiers: ["name", "state"],
    components: ["List"],
    custom_views: {
      show: CustomComponents.WorkflowAdminTool,
    },
    filters: ["name", "state", "terminal", "paused"],
    attributes: [
      { source: "name", label: "Workflow Type", sortable: true },
      { source: "state", label: "Current State", sortable: true },
      { source: "healthy", label: "Healthy?", sortable: true, type: "boolean" },
      {
        source: "complete",
        label: "Complete?",
        sortable: true,
        type: "boolean",
      },
      {
        source: "terminal",
        label: "Terminal?",
        sortable: true,
        type: "boolean",
      },
      { source: "paused", label: "Paused?", sortable: true, type: "boolean" },
      {
        source: "created_at",
        label: "Created At",
        sortable: true,
        type: "date",
      },
    ],
  },
  company_benefit_selection_window: {
    title: "Company Selection Windows",
    backend_resource: "company_benefit_selection_windows",
    components: ["Show", "Edit"],
    attributes: [
      {
        source: "name",
        label: "Company",
        reference: [["companies", "company_id"]],
        validations: { required: true },
      },
      {
        source: "year",
        label: "Plan Year",
        reference: [["plan_years", "plan_year_id"]],
        validations: { required: true },
      },
      {
        source: "name",
        label: "Company Benefit Cycle",
        reference: [["company_benefit_cycles", "company_benefit_cycle_id"]],
        filter_params: [["company_id", "company_id"]],
        sortable: true,
      },
      {
        source: "start_date",
        label: "Selection Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "Selection End Date",
        type: "date",
      },
      {
        source: "offering_start_date",
        label: "Offering Start Date",
        type: "date",
      },
      {
        source: "offering_end_date",
        label: "Offering End Date",
        type: "date",
      },
      {
        source: "waiting_period_waived",
        label: "Admin Waived Waiting Period",
        type: "nullable-boolean",
        nullLabel: "N/A",
        exclude: ["Create"],
      },
      {
        custom_component:
          CustomComponents.CompanyBenefitSelectionWindowProcessingManager(),
        exclude: ["Create", "Edit"],
      },
    ],
    sub_resources: [
      {
        tab_name: "Benefit Offerings",
        backend_resource: "company_benefit_offerings",
        linking_field: "company_benefit_selection_window_id",
        attributes: [
          {
            source: "name",
            label: "Company",
            reference: [["companies", "company_id"]],
            sortable: true,
          },
          {
            source: "name",
            label: "Company Benefit Cycle",
            reference: [["company_benefit_cycles", "company_benefit_cycle_id"]],
            filter_params: [["company_id", "company_id"]],
          },
          {
            source: "benefit_type",
            label: "Benefit Type",
            type: "string",
          },
          {
            source: "name",
            label: "Providers",
            reference: [["providers", "provider_ids"]],
            type: "multi-reference",
          },
          {
            source: "enrollment_period_start_date",
            label: "Enrollment Period Start Date",
            type: "date",
          },
          {
            source: "enrollment_period_end_date",
            label: "Enrollment Period End Date",
            type: "date",
          },
          {
            source: "offering_start_date",
            label: "Offering Start Date",
            type: "date",
          },
          {
            source: "offering_end_date",
            label: "Offering End Date",
            type: "date",
          },
          {
            source: "status",
            label: "Status",
            type: "string",
          },
        ],
        components: ["List", "Show"],
      },
    ],
  },
  member_benefit_selection_window: {
    title: "Member Selection Windows",
    backend_resource: "member_benefit_selection_windows",
    components: ["Show"],
    attributes: [
      {
        label: "Member",
        reference: [["members", "member_id"]],
      },
      {
        source: "coverage_start_date",
        label: "Coverage Start Date",
        type: "date",
      },
      {
        source: "start_date",
        label: "Start Date",
        type: "date",
      },
      {
        source: "end_date",
        label: "End Date",
        type: "date",
      },
      {
        custom_component:
          CustomComponents.MemberSelectionWindowProcessingManager(),
      },
    ],
    sub_resources: [
      {
        tab_name: "Plan Selections",
        backend_resource: "member_benefit_selections",
        linking_field: "member_benefit_selection_window_id",
        attributes: [
          {
            source: "display_name",
            label: "Company Benefit Offerings",
            reference: [
              ["company_benefit_offerings", "company_benefit_offering_ids"],
            ],
            type: "multi-reference",
            filter_params: [["company_id", "company_id"]],
            sort: "id",
          },
          {
            source: "display_name",
            label: "Plan",
            reference: [
              ["company_plan_offerings", "company_plan_offering_id"],
              ["plans", "plan_id"],
            ],
            filter_params: [
              ["company_id", "company_id"],
              ["selection_status", "confirmed"],
            ],
          },
          {
            source: "coverage_start_date",
            label: "Coverage Start Date",
            type: "date",
            validations: { required: true },
          },
          {
            source: "start_date",
            label: "Start Date",
            type: "date",
          },
          {
            source: "end_date",
            label: "End Date",
            type: "date",
          },
          {
            source: "selection_status",
            label: "Status",
          },
          {
            source: "medical_waiver_status",
            label: "Medical Waiver Status",
          },
          {
            label: "Dependent IDs",
            reference: [["member_dependents", "member_dependent_ids"]],
            type: "multi-reference",
            filter_params: [["member_id", "member_id"]],
          },
          {
            source: "incomplete_enrollment_prerequisites",
            label: "Incomplete Enrollment Prerequisites",
          },
          {
            custom_component: CustomComponents.ConvertSelectionButton(),
          },
          {
            custom_component:
              CustomComponents.NewHireMedicalInsuranceEnrollmentManager(),
          },
        ],
        components: ["List", "Show"],
      },
    ],
  },
  billing_deductions_request: {
    title: "Deductions",
    backend_resource: "billing_deductions_requests",
    components: ["Show"],
    read_only: true,
    attributes: [
      {
        source: "created_at",
        label: "Sent At",
      },
      { source: "payload.rate_amount", label: "Premium" },
      {
        source: "employee_contribution_amount",
        label: "Employee Contribution",
      },
      {
        source: "payload.company_contribution_amount",
        label: "Company Contribution",
      },
      {
        source: "payload.company_contribution_basis",
        label: "Contribution Basis",
      },
      { source: "payload.start_date", label: "Start Date" },
      { source: "payload.end_date", label: "End Date" },
      {
        source: "operation_type",
        label: "Operation",
      },
      {
        source: "enrollment_state_id",
        label: "Enrollment State ID",
      },
      { source: "response", label: "Response" },
    ],
  },
  assignment: {
    title: "Assignments",
    backend_resource: "assignments",
    components: ["Show"],
    attributes: [
      { source: "assignee_id", label: "Assignee Id", sortable: true },
      { source: "subject_resource", label: "Resource", sortable: true },
      { source: "subject_id", label: "Id", sortable: true },
    ],
  },
  ability_group: {
    title: "Ability Groups",
    backend_resource: "ability_groups",
    record_representation: "name",
    components: ["Show", "List"],
    attributes: [
      {
        source: "name",
        label: "Name",
      },
      {
        source: "abilities",
        label: "Abilities",
        type: "json",
      },
    ],
  },
  document_rules: {
    title: "Carrier Document Rules",
    backend_resource: "company_carrier_install_document_requirement_rules",
    components: ["List", "Show", "Edit", "Create"],
    attributes: [
      {
        source: "label",
        reference: [
          [
            "company_carrier_install_document_categories",
            "company_carrier_install_document_category_id",
          ],
        ],
        label: "Category",
        sortable: true,
      },
      {
        source: "rule_name",
        label: "Rule Name",
        sortable: false,
      },
      {
        source: "applies_to.hq_state",
        label: "Applies To States",
        type: "multi-reference",
        sortable: false,
      },
      {
        source: "requirements",
        label: "Requirements",
        reference: [
          [
            "company_carrier_install_document_types",
            "company_carrier_install_document_type_id",
          ],
        ],
        type: "multi-reference",
      },
    ],
    custom_views: {
      list: CustomComponents.DocumentRequirementList,
      show: CustomComponents.DocumentRequirementShow,
      edit: CustomComponents.DocumentRequirementEdit,
      create: CustomComponents.DocumentRequirementCreate,
    },
  },
  company_carrier_install_document_category: {
    title: "Document Categories",
    backend_resource: "company_carrier_install_document_categories",
    components: ["Show", "Edit", "Create"],
    attributes: [
      { source: "category_code", label: "Code", sortable: true },
      { source: "label", label: "Label", sortable: true },
    ],
  },
  company_carrier_install_document_type: {
    title: "Document Types",
    backend_resource: "company_carrier_install_document_types",
    components: ["Show", "Edit", "Create"],
    attributes: [
      { source: "type_code", label: "Code", sortable: true },
      { source: "label", label: "Label", sortable: true },
    ],
  },
  insurance_quote_request: {
    title: "Insurance Quote Requests",
    backend_resource: "company_insurance_quote_requests",
    components: ["Show", "List"],
    read_only: true,
    mapped_to: ["justworks", "zywave_account", "justworks_payroll"],
    filters: ["company_name", "state", "status"],
    custom_views: {
      create: CustomComponents.InsuranceQuoteRequestCreate,
    },
    attributes: [
      {
        source: "id",
        label: "id",
        exclude: ["List"],
      },
      {
        source: "company_name",
        label: "Company Name",
        sortable: true,
      },
      {
        source: "id",
        label: "Company Id",
        reference: [["companies", "company_id"]],
        exclude: ["List"],
      },
      {
        source: "offering_start_date",
        type: "date",
        label: "Offering Start Date",
      },
      {
        source: "offering_end_date",
        type: "date",
        label: "Offering End Date",
        exclude: ["List"],
      },
      {
        source: "selection_window_start_date",
        type: "date",
        label: "Selection Window Start Date",
        exclude: ["List"],
      },
      {
        source: "selection_window_end_date",
        type: "date",
        label: "Selection Window End Date",
        exclude: ["List"],
      },
      {
        source: "state",
        label: "Situs State",
        sortable: true,
      },
      {
        source: "county",
        label: "County",
        exclude: ["List"],
      },
      {
        source: "zip",
        label: "Zip-Code",
      },
      {
        source: "sic_code",
        label: "Sic code",
      },
      {
        source: "benefit_types",
        label: "Benefit Types",
      },
      {
        source: "group_size",
        label: "Group Size",
      },
      {
        source: "renewals",
        label: "Renewals",
        type: "boolean",
        exclude: ["List"],
      },
      {
        source: "zywave_quote_id",
        label: "Zywave Quote id",
        type: "string",
      },
      {
        source: "zywave_quote_url",
        label: "Zywave Quote URL",
        type: "url",
        exclude: ["List"],
      },
      {
        source: "beam_quote_id",
        label: "Beam Quote id",
        type: "string",
      },
      {
        custom_component:
          CustomComponents.CompanyInsuranceQuoteRequestProcessingManager(),
        exclude: ["Create"],
      },
    ],
    sub_resources: [
      {
        tab_name: "Censuses",
        backend_resource: "company_censuses",
        linking_field: "company_insurance_quote_request_id",
        attributes: [
          {
            source: "offering_start_date",
            label: "Start Date",
            type: "string",
            reference: [
              [
                "company_insurance_quote_requests",
                "company_insurance_quote_request_id",
              ],
            ],
          },
          {
            source: "census_rows",
            label: "Census Rows",
            type: "json",
            exclude: ["List"],
          },
        ],
        components: ["List", "Show"],
      },
    ],
  },
  file_feeds: {
    title: "File Feed Configuration",
    backend_resource: "file_feeds",
    components: ["Show", "List", "Create", "Edit"],
    record_representation: "file_feed_title",
    attributes: [
      {
        source: "id",
        label: "ID",
      },
      {
        source: "file_feed_title",
        label: "Title",
        type: "string",
      },
      {
        source: "file_feed_name",
        label: "File Name",
        type: "string",
      },
      {
        source: "file_feed_type",
        label: "File Feed Direction",
        choices: Choices.FILE_FEED_DIRECTION_CHOICES,
        type: "drop-down",
        defaultValue: "outgoing",
      },
      {
        source: "file_feed_extension",
        label: "File Feed Extension",
        type: "string",
      },
      {
        source: "sftp_host",
        label: "SFTP Host",
        type: "string",
      },
      {
        source: "sftp_username",
        label: "SFTP Username",
        type: "string",
      },
      {
        source: "sftp_password",
        label: "SFTP Password",
        type: "string",
      },
      {
        source: "sftp_directory",
        label: "SFTP directory",
        type: "string",
      },
      {
        source: "gpg_id",
        label: "GPG ID",
        type: "string",
      },
      {
        source: "gpg_key",
        label: "GPG Key",
        type: "string",
        multiline: true,
        style: { width: "800px" },
      },
      {
        source: "sftp_port",
        label: "SFTP Port",
        type: "number",
      },
    ],
    sub_resources: [
      {
        tab_name: "File Feed Runs",
        backend_resource: "file_feed_runs",
        linking_field: "file_feed_id",
        attributes: [
          {
            source: "file_feed_run_title",
            label: "File Feed Run Title",
            type: "string",
            exclude: ["Create"],
          },
          {
            source: "file_feed_id",
            label: "File Feed Config Id",
          },
          {
            source: "date",
            label: "Date",
            type: "date",
          },
          {
            source: "internal_absolute_path",
            label: "Internal Absolute Path",
            exclude: ["Create"],
          },
          {
            custom_component:
              CustomComponents.FileFeedDownloadButton("unencrypted"),
            label: "Download Unencrypted",
            exclude: ["Create"],
          },
          {
            source: "internal_encrypted_absolute_path",
            label: "Internal Encrypted file",
            exclude: ["Create"],
          },
          {
            label: "Download Encrypted",
            custom_component:
              CustomComponents.FileFeedDownloadButton("encrypted"),
            exclude: ["Create"],
          },
          {
            source: "sftp_absolute_path",
            label: "SFTP Absolute Path",
            exclude: ["Create"],
          },
          {
            source: "status",
            type: "string",
            exclude: ["Create"],
          },
          {
            source: "md5_checksun",
            type: "string",
            exclude: ["Create"],
          },
          {
            source: "created_at",
            type: "date",
            label: "Created At",
            exclude: ["Create"],
          },
        ],
        components: ["List", "Show", "Create"],
      },
    ],
  },
  file_feeds_runs: {
    title: "File Feed Run",
    backend_resource: "file_feed_runs",
    components: ["Show"],
    record_representation: "file_feed_title",
    attributes: [
      {
        source: "file_feed_run_title",
        label: "File Feed Run Title",
        type: "string",
        exclude: ["Create"],
      },
      {
        source: "file_feed_id",
        label: "File Feed Config Id",
      },
      {
        source: "internal_absolute_path",
        label: "Internal Absolute Path",
        exclude: ["Create"],
      },
      {
        source: "internal_encrypted_absolute_path",
        label: "Encrypted Absolute Path",
        exclude: ["Create"],
      },
      {
        source: "sftp_absolute_path",
        label: "SFTP Absolute Path",
        exclude: ["Create"],
      },
      {
        source: "status",
        type: "string",
        exclude: ["Create"],
      },
      {
        source: "md5_checksun",
        type: "string",
        exclude: ["Create"],
      },
      {
        source: "created_at",
        type: "date",
        label: "Created At",
        exclude: ["Create"],
      },
    ],
  },
  dependents: Resources.Dependents.top_level_resource_config,
  dependent_addresses: Resources.DependentAddresses.top_level_resource_config,
  utilities: Resources.Utilities.top_level_resource_config,
  bulk_member_export: Resources.BulkMemberExport.top_level_resource_config,
  company_hsa_contribution_schemes:
    Resources.CompanyHsaContributionSchemes.top_level_resource_config,
  optum_events: Resources.OptumEvents.top_level_resource_config,
};

export default BlueprintConfig;
