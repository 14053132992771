import React from "react";
import { Button } from "react-admin";
import { useRecordContext } from "react-admin";
import { BENEFITS_OPS_DASH_HOST } from "lib/constants";

const RolloverButtonComponent = function RolloverButton() {
  const record = useRecordContext();

  const openRolloverDshboardInNewTab = () => {
    const dashboardUrl = `${BENEFITS_OPS_DASH_HOST}/health/open-market/company-benefit-offerings/${record.id}/rollover`;

    window.open(dashboardUrl, "_blank");
  };

  return (
    <div>
      <Button
        label="Rollover Benefit Offering"
        onClick={openRolloverDshboardInNewTab}
      />
    </div>
  );
};

const RolloverButton = function RolloverButton() {
  return <RolloverButtonComponent key="RolloverButtonComponent" />;
};

export default RolloverButton;
